import { Button, Typography } from 'antd';

const { Title } = Typography;

function onClick() {
  window.location.replace('/');
}

function Custom404() {
  return (
    <section className="h-screen flex flex-col justify-center items-center">
      <div className="flex flex-col items-center">
        <Title level={1}>404 - Page Not Found</Title>
      </div>
      <Button type="primary" onClick={onClick}>
        Back to Home
      </Button>
    </section>
  );
}

export default Custom404;
